<template>
  <div id="app">
    <mainDiv />
  </div>
</template>

<script>
import mainDiv from '@/components/main.vue';

export default {
  name: 'App',
  components: {
    mainDiv,
  }
}
</script>

<style>

</style>
